import {FC, PropsWithChildren} from 'react';

const widths = {
  'extra-large': 'max-w-[1600px]',
  large: 'max-w-7xl',
  medium: 'max-w-xl',
  form: 'max-w-md',
};

export const Container: FC<
  PropsWithChildren<{
    usePadding?: boolean;
    maxWidth?: 'medium' | 'large' | 'extra-large' | 'form';
    className?: string;
  }>
> = ({children, usePadding, maxWidth = 'large', className = ''}) => (
  <div
    className={`${widths[maxWidth]} mx-auto ${
      usePadding ? 'px-4 sm:px-6 lg:px-8' : ''
    } ${className}`}
  >
    {children}
  </div>
);
